import { __LandingLang2 } from './landing.lang';
import { RiDiscountPercentFill, RiHeartsFill } from 'react-icons/ri';
import { GoHomeFill } from 'react-icons/go';
import { HiUserGroup } from 'react-icons/hi';
import service1 from 'assets/images/landing2/service1.svg';
import service2 from 'assets/images/landing2/service2.svg';
import service3 from 'assets/images/landing2/service3.svg';
import service4 from 'assets/images/landing2/service4.svg';
import { MBTIEnum, ServiceTypeEnum } from '@/types';
import p1 from 'assets/images/fake-dating-profiles/1.webp';
import p2 from 'assets/images/fake-dating-profiles/2.webp';
import p3 from 'assets/images/fake-dating-profiles/3.webp';
import p4 from 'assets/images/fake-dating-profiles/4.webp';
import p5 from 'assets/images/fake-dating-profiles/5.webp';
import p6 from 'assets/images/fake-dating-profiles/6.webp';
import p7 from 'assets/images/fake-dating-profiles/7.webp';
import p8 from 'assets/images/fake-dating-profiles/8.webp';
import p9 from 'assets/images/fake-dating-profiles/9.webp';
import p11 from 'assets/images/fake-dating-profiles/11.webp';
import p12 from 'assets/images/fake-dating-profiles/12.webp';
import p13 from 'assets/images/fake-dating-profiles/13.webp';
import p14 from 'assets/images/fake-dating-profiles/14.webp';
import p15 from 'assets/images/fake-dating-profiles/15.webp';
import p16 from 'assets/images/fake-dating-profiles/16.webp';
import p18 from 'assets/images/fake-dating-profiles/18.webp';
import p19 from 'assets/images/fake-dating-profiles/19.webp';
import p20 from 'assets/images/fake-dating-profiles/20.webp';
import p21 from 'assets/images/fake-dating-profiles/21.webp';
import p22 from 'assets/images/fake-dating-profiles/22.webp';
import p23 from 'assets/images/fake-dating-profiles/23.webp';
import p24 from 'assets/images/fake-dating-profiles/24.webp';
import p25 from 'assets/images/fake-dating-profiles/25.webp';
import p26 from 'assets/images/fake-dating-profiles/26.webp';
import p27 from 'assets/images/fake-dating-profiles/27.webp';

export const __LandingData = (lang: typeof __LandingLang2) => {
    return [
        {
            id: 1,
            title: lang.service1,
            desc: lang.desc1,
            size: 42,
            src: service1,
        },
        {
            id: 2,
            title: lang.service2,
            desc: lang.desc2,
            size: 56,
            src: service2,
        },
        {
            id: 3,
            title: lang.service3,
            desc: lang.desc3,
            size: 42,
            src: service3,
        },
        {
            id: 4,
            title: lang.service4,
            desc: lang.desc4,
            size: 42,
            src: service4,
        },
    ];
};

export const __HeaderList = (lang: typeof __LandingLang2) => {
    return [
        {
            id: ServiceTypeEnum.offer,
            title: lang.buy,
            icon: RiDiscountPercentFill,
        },
        {
            id: ServiceTypeEnum.dating,
            title: lang.friend,
            icon: RiHeartsFill,
        },
        {
            id: ServiceTypeEnum.housing,
            title: lang.home,
            icon: GoHomeFill,
        },
        {
            id: ServiceTypeEnum.events,
            title: lang.events,
            icon: HiUserGroup,
        },
    ];
};

export const __DatingFakeData = [
    {
        avatar: p1,
        gender: 'female',
        mbti: MBTIEnum.ENFJ,
        name: 'Sara',
        age: 23,
        location: 'downtown toronto',
    },
    {
        avatar: p2,
        gender: 'female',
        mbti: MBTIEnum.ENFP,
        name: 'Helena',
        age: 21,
        location: 'british colombia',
    },
    {
        avatar: p3,
        gender: 'female',
        mbti: MBTIEnum.ENTJ,
        name: 'Zahra',
        age: 28,
        location: 'Tehran',
    },
    {
        avatar: p4,
        gender: 'female',
        mbti: MBTIEnum.ENTP,
        name: 'Mona',
        age: 21,
        location: 'Richmond Hill',
    },
    {
        avatar: p5,
        gender: 'male',
        mbti: MBTIEnum.ESFJ,
        name: 'Ali',
        age: 21,
        location: 'Brampton Hill',
    },
    {
        avatar: p6,
        gender: 'female',
        mbti: MBTIEnum.ESFP,
        name: 'Neda',
        age: 26,
        location: 'Mississauga',
    },
    {
        avatar: p7,
        gender: 'female',
        mbti: MBTIEnum.ESTJ,
        name: 'Nooshin',
        age: 25,
        location: 'Oshawa',
    },
    {
        avatar: p8,
        gender: 'female',
        mbti: MBTIEnum.INFJ,
        name: 'Ana',
        age: 31,
        location: 'downtown toronto',
    },
    {
        avatar: p9,
        gender: 'male',
        mbti: MBTIEnum.INFP,
        name: 'Borna',
        age: 32,
        location: 'british colombia',
    },
    {
        avatar: p11,
        gender: 'female',
        mbti: MBTIEnum.INTJ,
        name: 'Hasti',
        age: 31,
        location: 'Tehran',
    },
    {
        avatar: p12,
        gender: 'female',
        mbti: MBTIEnum.INTP,
        name: 'Hana',
        age: 24,
        location: 'Richmond Hill',
    },
    {
        avatar: p13,
        gender: 'male',
        mbti: MBTIEnum.ISFJ,
        name: 'Amin',
        age: 23,
        location: 'Brampton Hill',
    },
    {
        avatar: p14,
        gender: 'female',
        mbti: MBTIEnum.ISFP,
        name: 'Ronak',
        age: 36,
        location: 'Mississauga',
    },
    {
        avatar: p15,
        gender: 'male',
        mbti: MBTIEnum.ISTJ,
        name: 'Kia',
        age: 27,
        location: 'downtown toronto',
    },
    {
        avatar: p16,
        gender: 'female',
        mbti: MBTIEnum.ISTP,
        name: 'Elena',
        age: 29,
        location: 'british colombia',
    },
    {
        avatar: p18,
        gender: 'female',
        mbti: MBTIEnum.ENFJ,
        name: 'Setareh',
        age: 22,
        location: 'Tehran',
    },
    {
        avatar: p19,
        gender: 'female',
        mbti: MBTIEnum.ENFP,
        name: 'Donya',
        age: 24,
        location: 'Richmond Hill',
    },
    {
        avatar: p20,
        gender: 'female',
        mbti: MBTIEnum.ENTJ,
        name: 'Parnia',
        age: 26,
        location: 'Brampton Hill',
    },
    {
        avatar: p21,
        gender: 'male',
        mbti: MBTIEnum.ENTP,
        name: 'Yasin',
        age: 29,
        location: 'Mississauga',
    },
    {
        avatar: p22,
        gender: 'male',
        mbti: MBTIEnum.ESFJ,
        name: 'Mohsen',
        age: 34,
        location: 'downtown toronto',
    },
    {
        avatar: p23,
        gender: 'female',
        mbti: MBTIEnum.ESFP,
        name: 'Ronak',
        age: 24,
        location: 'british colombia',
    },
    {
        avatar: p24,
        gender: 'female',
        mbti: MBTIEnum.ESTJ,
        name: 'Ziba',
        age: 22,
        location: 'Tehran',
    },
    {
        avatar: p25,
        gender: 'male',
        mbti: MBTIEnum.ESTP,
        name: 'Amir',
        age: 27,
        location: 'Richmond Hill',
    },
    {
        avatar: p26,
        gender: 'female',
        mbti: MBTIEnum.INFJ,
        name: 'Elahe',
        age: 30,
        location: 'Brampton Hill',
    },
    {
        avatar: p27,
        gender: 'male',
        mbti: MBTIEnum.INFP,
        name: 'Omid',
        age: 28,
        location: 'Mississauga',
    },
];
